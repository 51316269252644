





































































































































































































import { mapGetters, mapActions } from "vuex"
import isUserSubmitMode from "@shared/helpers/isUserSubmitMode"
import UserVideo from "@/components/GroupTeams/Common/User/UserVideo.vue"

import { ActionTypes as TwilioModuleActionTypes } from "@/store/TwilioModule"

import ResizableText from "@/components/GroupTeams/Common/Games/ResizableText.vue"
import uniqid from "uniqid"
import { GameMixin } from "@/mixins"
import { uploadMedia } from "@/services/storage.service"
import { RtbButton } from "@/components/ui"
import PlyrWrap from "@/components/GroupTeams/Common/Games/GameCardParts/PlyrWrap.vue"

import Mode from "@shared/enums/Mode"
import MissionType from "@shared/enums/Mission"
import User from "@shared/User"
import { UserRole } from "@/types/user"

import { TimeoutMixinFactory } from "@/mixins/timeout"

import { db } from "@/firebase"

const MAX_RECORDING_TIME = 60 * 1000
const DEFAULT_VIDEO_LENGTH = 6
const RECORDER_STATE = {
  RECORDING: "RECORDING",
  STOPPED: "STOPPED",
  PAUSED: "PAUSED"
}

enum VideoTypes {
  mp4 = "video/mp4",
  webm = "video/webm"
}

const VIDEO_TYPE_TO_EXTENSION = {
  [VideoTypes.mp4]: "mp4",
  [VideoTypes.webm]: "webm"
}

const supportedVideoType = [VideoTypes.mp4, VideoTypes.webm].find(
  MediaRecorder.isTypeSupported
)

const supportedVideoExtension = VIDEO_TYPE_TO_EXTENSION[supportedVideoType]

const STEP = {
  RECORD: "RECORD",
  SUBMIT: "SUBMIT"
}

const secondsToMs = seconds => seconds * 1000
const msToseconds = ms => ms / 1000

let frames = []
let frameIdx = 0
let data = []

export default {
  name: "TakeVideo",
  mixins: [GameMixin, TimeoutMixinFactory()],
  components: {
    PlyrWrap,
    ResizableText,
    RtbButton,
    UserVideo
  },
  props: {
    mission: Object,
    customUser: {
      required: false,
      type: Object
    }
  },
  data() {
    return {
      data: [],
      recorder: null,
      videoUrl: null,
      mediaStream: null,
      recordedBlob: null,
      recordingSrc: null,
      step: null,
      recorderState: null,
      RECORDER_STATE,
      STEP,
      playing: false,
      working: false,
      workingToRecord: false,
      recordingPlayBack: false,
      audioCtx: null,
      streamReady: false,
      recordingDelayInterval: 0,
      recordingDelayIntervalPaused: false,
      gif: null,
      isProcesingGif: false,
      Mode
    }
  },
  beforeDestroy() {
    clearInterval(this._delayIntervalID)
  },
  watch: {
    step: {
      async handler(val, prevVal) {
        if (prevVal === STEP.RECORD && (!val || this.step === STEP.SUBMIT)) {
          // stoped recording
          if (this.user?.isRecording) {
            await this.updateUser({
              userID: this.user?.id,
              obj: { isRecording: null }
            })
          }
        }
      },
      immediate: true
    },
    recordingPlayBack(val, prevVal) {
      if (prevVal === true && !val) {
        try {
          this.onStopRecording()
        } catch (err) {
          console.log(err.message)
        }
      }
    }
  },
  computed: {
    ...mapGetters("twilio", { twilioUsers: "users" }),
    ...mapGetters({ teams: "chats" }),
    ...mapGetters("auth", ["token"]),
    ...mapGetters(["gameID", "orgID", "game"]),
    ...mapGetters(["missionPlaysArray"]),
    ...mapGetters({ mode: "getCurrentMode", teams: "chats" }),
    isViewerAuditorLike() {
      return this.$store.getters["group/isViewerAuditorLike"]
    },
    instructions() {
      return this.mission.instructions
    },
    presentationMission() {
      return this.mission?.presentationImage
    },
    localVideoTrack() {
      return this.twilioUsers[this.user?.id]?.videoTrack
    },
    localAudioTrack() {
      return this.twilioUsers[this.user?.id]?.audioTrack
    },
    shouldHaveAudio() {
      return this.mission?.withAudio
    },
    isBoomerang() {
      return this.mission?.boomerang
    },
    currentMissionID() {
      return this.mission?.id
    },
    isScribe() {
      return User.isScribe(this.user)
    },
    isHost() {
      return [UserRole.Host, UserRole.Audit].includes(this.user.role)
    },
    user() {
      return this.customUser ?? this.$store.getters.user
    },
    canTakeVideo() {
      return Boolean(
        this.isUserSubmitMode &&
          (this.mission.behavior === MissionType.VideoTeam ||
            this.isHost ||
            this.isScribe)
      )
    },
    countdownVisible() {
      return this.intervalID !== null
    },
    videoCountdownVisible() {
      return !!this.recordingDelayInterval
    },
    isPaused() {
      return this.recorderState === RECORDER_STATE.PAUSED
    },
    stillRecording() {
      return [null, "RECORDING", "PAUSED"].includes(this.recorderState)
    },
    isExtendedVideo() {
      return [MissionType.VideoIndividual, MissionType.VideoTeam].includes(
        this.mission.behavior
      )
    },
    hasTimeLimit() {
      const time = this.mission?.videoMaxRecTime
      return parseInt(time) || DEFAULT_VIDEO_LENGTH
    },
    isExtendedVideoTypeProperlySet() {
      return this.isExtendedVideo && this.hasTimeLimit
    },
    isUserSubmitMode() {
      return isUserSubmitMode(this.getCurrentMode)
    },
    team() {
      return this.teams?.[this.user.teamID]
    },
    userMissionPlay() {
      return this.missionPlaysArray.find(
        ({ teamID, userID }) =>
          (this.isHost || teamID === this.user?.teamID) &&
          userID === this.user?.id
      )
    },
    hasSubmitted() {
      if (MissionType.VideoTeam === this.mission.behavior)
        return Boolean(this.team?.teamVideos?.[this.user?.id])
      else return Boolean(this.userMissionPlay)
    },
    recordingSrcComputed() {
      return (
        this.recordingSrc || this.userMissionPlay?.answer?.[0]?.video || null
      )
    }
  },
  methods: {
    ...mapActions("twilio", [
      TwilioModuleActionTypes.CREATE_LOCAL_VIDEO_TRACK,
      TwilioModuleActionTypes.CREATE_LOCAL_AUDIO_TRACK
    ]),
    ...mapActions(["updateUser"]),
    async submit() {
      this.working = true
      const fileName = `gamevideos/${uniqid()}-${
        this.user.username
      }.${supportedVideoExtension}`
      try {
        this.videoUrl = await uploadMedia({
          fileName,
          blob: this.recordedBlob,
          token: this.token
        })

        if (MissionType.VideoTeam !== this.mission.behavior) {
          this.missionStatus = "completed"
          await this.checkAnswer({
            sentMissionID: this.currentMissionID
          })
        } else {
          const { teamID, id } = this.user
          await db
            .auxiliary()
            .ref(
              `org/${this.orgID}/game/${this.gameID}/teams/${teamID}/teamVideos/${id}`
            )
            .update({
              video: this.videoUrl,
              userID: this.user.id,
              correct: true
            })
        }
        this.working = false
      } catch (e) {
        this.working = false
        console.log(e.message)
        alert(
          "It looks like you are behind a firewall or VPN and therefore can't save your video."
        )
      }
    },
    wait(delayInMS) {
      return new Promise(resolve => {
        clearInterval(this._delayIntervalID)
        this.recordingDelayInterval = msToseconds(delayInMS)
        this._delayIntervalID = setInterval(() => {
          if (!this.recordingDelayIntervalPaused) {
            this.recordingDelayInterval--
            console.log(this.recordingDelayInterval)
          }
          if (!this.recordingDelayInterval) {
            clearInterval(this._delayIntervalID)
            // @ts-expect-error TODO:
            resolve()
          }
        }, 1000)
      })
    },
    onStartRecordingHandler() {
      this.workingToRecord = true
      if (this.isExtendedVideo) {
        this.onStartRecording()
      } else {
        this.runTimeout(this.onStartRecording)
      }
    },
    async onStartRecording() {
      if (this.audioCtx) {
        this.audioCtx.close()
        this.audioCtx = null
      }
      // @ts-expect-error TODO:
      this.audioCtx = new (window.AudioContext || window.webkitAudioContext)()
      this.workingToRecord = true
      if (!this.user?.muted) {
        await this.updateUser({
          userID: this.user?.id,
          obj: { isRecording: true }
        }).catch(e => {
          console.log(e.message)
        })
      }
      this.step = STEP.RECORD
      this.recorderState = RECORDER_STATE.RECORDING

      const recordingTime =
        this.isExtendedVideo && this.hasTimeLimit
          ? secondsToMs(this.hasTimeLimit)
          : MAX_RECORDING_TIME

      this.startRecording(recordingTime)
        .then(async recordedChunks => {
          clearInterval(this._delayIntervalID)
          this.recordedBlob = new Blob(recordedChunks, {
            type: supportedVideoType
          })
          const video = new File(
            [this.recordedBlob],
            `video.${supportedVideoExtension}`,
            {
              type: supportedVideoType
            }
          )

          this.recordingSrc = window.URL.createObjectURL(video)
          URL.revokeObjectURL(this.recordedBlob)

          this.recorderState = RECORDER_STATE.STOPPED
          this.step = STEP.SUBMIT
          this.workingToRecord = false
        })
        .catch(err => {
          console.log(err.message)
          this.workingToRecord = false
        })
    },
    startRecording(lengthInMS) {
      data = []
      const canvas = document.createElement("canvas")
      const context = canvas.getContext("2d")

      const video = this.$refs?.video.$refs.video
      const { clientWidth: videoWidth, clientHeight: videoHeight } = video

      canvas.width = videoWidth || 250
      canvas.height = videoHeight || 250

      let canvasStream = canvas.captureStream(25)
      const [canvasStreamTrack] = canvasStream.getTracks()

      const localAudioTrack = this.localAudioTrack.mediaStreamTrack

      if (localAudioTrack && this.shouldHaveAudio) {
        this.audioSource = this.audioCtx.createMediaStreamSource(
          new MediaStream([localAudioTrack])
        )
        this.gainNode = this.audioCtx.createGain()
        this.destination = this.audioCtx.createMediaStreamDestination()
        this.audioSource.connect(this.gainNode)
        this.gainNode.connect(this.destination)
        canvasStream = this.destination.stream
        canvasStream.addTrack(canvasStreamTrack)
      }

      this.recorder = new MediaRecorder(canvasStream)
      this.recorder.ondataavailable = event => data.push(event.data)
      this.recorder.start(500)

      let stopped = new Promise((resolve, reject) => {
        this.recorder.onstop = resolve
        data = []
        this.recorder.onerror = event => reject(event.name)
      })

      let recorded = this.wait(lengthInMS).then(() => {
        this.recorder.state === "recording" && this.recorder.stop()
      })

      const draw = () => {
        if (this.recorderState === RECORDER_STATE.STOPPED) {
          return
        }

        const hRatio = canvas.width / video.videoWidth
        const vRatio = canvas.height / video.videoHeight

        const ratio = Math.max(hRatio, vRatio)

        const scaledWidth = video.videoWidth * ratio
        const scaledHeight = video.videoHeight * ratio

        const centerShift_x = (canvas.width - scaledWidth) / 2
        const centerShift_y = (canvas.height - scaledHeight) / 2

        if (this.recorderState !== RECORDER_STATE.PAUSED) {
          if (!this.recordingPlayBack) {
            context.drawImage(
              video,
              0,
              0,
              video.videoWidth,
              video.videoHeight,
              centerShift_x,
              centerShift_y,
              scaledWidth,
              scaledHeight
            )

            const imageData = context.getImageData(
              0,
              0,
              videoWidth,
              videoHeight
            )
            frames = [imageData, ...frames]
          } else {
            context.putImageData(frames[frameIdx], 0, 0)
            frameIdx = frameIdx + 1
          }
        }

        if (frameIdx === frames.length) {
          this.recordingPlayBack = false
          frameIdx = 0
          frames = []
          return
        }

        // canvasStreamTrack.requestFrame()
        requestAnimationFrame(draw)
      }

      draw()

      return Promise.race([stopped, recorded]).then(() => data)
    },
    async requestStopRecording() {
      this.recorder.pause()
      if (this.localAudioTrack.mediaStreamTrack && this.shouldHaveAudio) {
        // just a hack to mute the first Node
        // since doing 'this.audioSource.disconnect()' stops
        // the recorder
        this.gainNode.gain.value = 0
        setTimeout(async () => {
          try {
            const recordedBlob = new Blob(data, {
              type: "audio/webm;codecs=opus"
            })
            const arrayBuffer = await recordedBlob.arrayBuffer()
            const audioBuffer = await this.audioCtx.decodeAudioData(arrayBuffer)
            const source = this.audioCtx.createBufferSource()

            // reverse audio
            for (let i = 0; i < audioBuffer.numberOfChannels; i++) {
              Array.prototype.reverse.call(audioBuffer.getChannelData(i))
            }

            source.buffer = audioBuffer
            source.connect(this.destination)
            this.recorder.resume()
            source.start()
          } catch (e) {
            this.recorder.resume()
            console.log(e)
          }
        }, 600)
      }
      this.recordingPlayBack = true
    },
    async onStopRecording() {
      clearInterval(this._delayIntervalID)
      this.recorderState = RECORDER_STATE.STOPPED
      this.step = STEP.SUBMIT
      this.recorder.state == "recording" && this.recorder.stop()
    },
    watch() {
      const { recorded } = this.$refs
      if (recorded) {
        this.playing = true
        recorded.play().catch(err => {
          console.log(err)
          this.playing = false
        })
      }
    },
    stop() {
      const { recorded } = this.$refs
      if (recorded) {
        this.playing = false
        recorded.pause()
      }
    },
    redo() {
      this.recordedBlob = this.recordingSrc = this.step = null
      this.step = STEP.RECORD
      frames = []
      this.workingToRecord = false
      this.recorderState = null
      this.recordingPlayBack = false
    },
    pauseRecording() {
      if (this.recorder.state === "recording") {
        this.recordingDelayIntervalPaused = true
        this.recorder.pause()
        this.recorderState = RECORDER_STATE.PAUSED
      }
    },
    resumeRecording() {
      if (this.recorder.state === "paused") {
        this.recordingDelayIntervalPaused = false
        this.recorder.resume()
        this.recorderState = RECORDER_STATE.RECORDING
      }
    }
  }
}
