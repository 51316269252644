






import Vue from "vue"
export default Vue.extend({
  name: "PlyrWrap",
  props: {
    plyrConfig: {
      type: [Object, Boolean],
      default: () => ({})
    },
    controls: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    config() {
      return JSON.stringify({
        controls: this.controls && ["play", "progress", "current-time"],
        keyboard: { focused: false, global: false },
        ...this.plyrConfig
      })
    }
  }
})
