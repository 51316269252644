var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "take-video" },
    [
      _vm.canTakeVideo
        ? [
            _c(
              "div",
              { staticClass: "take-video__media" },
              [
                _vm.isViewerAuditorLike
                  ? _c("ResizableText", {
                      staticClass: "h-full w-full",
                      attrs: { message: "The users are recording" },
                    })
                  : _c(
                      "div",
                      { staticClass: "take-video__media-container" },
                      [
                        (_vm.step === _vm.STEP.RECORD || !_vm.step) &&
                        !_vm.hasSubmitted
                          ? [
                              _vm.localVideoTrack
                                ? _c("UserVideo", {
                                    ref: "video",
                                    staticClass: "take-video__video",
                                    attrs: { track: _vm.localVideoTrack },
                                    on: {
                                      ready: function ($event) {
                                        _vm.streamReady = true
                                      },
                                    },
                                  })
                                : _vm._e(),
                              _vm.recorderState === _vm.RECORDER_STATE.RECORDING
                                ? _c("v-icon", { staticClass: "record-icon" }, [
                                    _vm._v(" fiber_manual_record "),
                                  ])
                                : _vm._e(),
                              _vm.countdownVisible
                                ? _c(
                                    "span",
                                    { staticClass: "take-video__countdown" },
                                    [_vm._v(" " + _vm._s(_vm.countdown) + " ")]
                                  )
                                : _vm._e(),
                              !_vm.streamReady && !_vm.recordingSrcComputed
                                ? _c("v-progress-circular", {
                                    staticClass: "take-video__loader",
                                    attrs: { indeterminate: "" },
                                  })
                                : _vm._e(),
                            ]
                          : _c("PlyrWrap", {
                              attrs: { controls: !_vm.isExtendedVideo },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var config = ref.config
                                      return _c("video", {
                                        ref: "recorded",
                                        staticClass: "take-video__video",
                                        attrs: {
                                          kind: "video",
                                          muted: "",
                                          autoplay: "",
                                          playsInline: "",
                                          src: _vm.recordingSrcComputed,
                                          loop: _vm.isExtendedVideo,
                                          "data-plyr-config": config,
                                        },
                                        domProps: { muted: true },
                                        on: {
                                          canplay: _vm.watch,
                                          ended: function ($event) {
                                            _vm.playing = false
                                          },
                                          pause: function ($event) {
                                            _vm.playing = false
                                          },
                                        },
                                      })
                                    },
                                  },
                                ],
                                null,
                                false,
                                2318935584
                              ),
                            }),
                      ],
                      2
                    ),
              ],
              1
            ),
            _vm.streamReady && _vm.isUserSubmitMode
              ? _c(
                  "v-layout",
                  {
                    attrs: {
                      "align-center": "",
                      "justify-center": "",
                      "d-flex": "",
                      shrink: "",
                      "mb-2": "",
                    },
                  },
                  [
                    _vm.hasSubmitted
                      ? _c("v-flex", [_c("h2", [_vm._v("Submitted")])])
                      : [
                          _vm.stillRecording
                            ? _c(
                                "v-flex",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "justify-content": "center",
                                  },
                                  attrs: { column: "" },
                                },
                                [
                                  _vm.countdownVisible
                                    ? _c(
                                        "RtbButton",
                                        {
                                          staticClass:
                                            "record-btn record-btn__countdown",
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(_vm.countdown) + " "
                                          ),
                                        ]
                                      )
                                    : !_vm.recorderState
                                    ? _c(
                                        "RtbButton",
                                        {
                                          staticClass: "record-btn",
                                          attrs: {
                                            disabled: _vm.workingToRecord,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.onStartRecordingHandler()
                                            },
                                          },
                                        },
                                        [
                                          _c("SvgIcon", {
                                            attrs: {
                                              name: "record",
                                              width: "24",
                                              height: "24",
                                            },
                                          }),
                                          _vm._v(" RECORD "),
                                        ],
                                        1
                                      )
                                    : [
                                        _vm.videoCountdownVisible
                                          ? _c(
                                              "RtbButton",
                                              {
                                                staticClass: "record-btn mr-3",
                                                attrs: { outline: "" },
                                              },
                                              [
                                                _c("SvgIcon", {
                                                  attrs: {
                                                    name: "record",
                                                    width: "24",
                                                    height: "24",
                                                  },
                                                }),
                                                _c("span", [
                                                  _vm._v(
                                                    "Recording (" +
                                                      _vm._s(
                                                        _vm.recordingDelayInterval
                                                      ) +
                                                      ")"
                                                  ),
                                                ]),
                                              ],
                                              1
                                            )
                                          : !_vm.isExtendedVideo
                                          ? [
                                              !_vm.isPaused
                                                ? _c(
                                                    "RtbButton",
                                                    {
                                                      staticClass: "mb-2 mr-3",
                                                      attrs: { outline: "" },
                                                      on: {
                                                        click:
                                                          _vm.pauseRecording,
                                                      },
                                                    },
                                                    [_vm._v(" PAUSE ")]
                                                  )
                                                : _c(
                                                    "RtbButton",
                                                    {
                                                      attrs: { outline: "" },
                                                      on: {
                                                        click:
                                                          _vm.resumeRecording,
                                                      },
                                                    },
                                                    [_vm._v(" RESUME ")]
                                                  ),
                                            ]
                                          : _vm._e(),
                                        !_vm.isExtendedVideoTypeProperlySet
                                          ? [
                                              _vm.isBoomerang
                                                ? _c(
                                                    "RtbButton",
                                                    {
                                                      staticClass: "mb-2 mr-3",
                                                      attrs: {
                                                        disabled:
                                                          _vm.recordingPlayBack,
                                                        outline: "",
                                                      },
                                                      on: {
                                                        click:
                                                          _vm.requestStopRecording,
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-flex",
                                                        {
                                                          attrs: {
                                                            "d-flex": "",
                                                            "align-self-center":
                                                              "",
                                                          },
                                                        },
                                                        [
                                                          _vm.recordingPlayBack
                                                            ? _c(
                                                                "v-progress-circular",
                                                                {
                                                                  staticClass:
                                                                    "spinner",
                                                                  attrs: {
                                                                    indeterminate:
                                                                      "",
                                                                  },
                                                                }
                                                              )
                                                            : _vm._e(),
                                                          _c(
                                                            "v-flex",
                                                            {
                                                              attrs: {
                                                                "align-self-center":
                                                                  "",
                                                              },
                                                            },
                                                            [_vm._v("DONE")]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _c(
                                                    "RtbButton",
                                                    {
                                                      attrs: { outline: "" },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.onStopRecording()
                                                        },
                                                      },
                                                    },
                                                    [_vm._v(" DONE ")]
                                                  ),
                                            ]
                                          : _vm._e(),
                                      ],
                                ],
                                2
                              )
                            : _c(
                                "v-flex",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "justify-content": "center",
                                  },
                                  attrs: { column: "" },
                                },
                                [
                                  !_vm.isExtendedVideo
                                    ? [
                                        !_vm.playing
                                          ? _c(
                                              "RtbButton",
                                              {
                                                staticClass: "mr-3",
                                                attrs: { outline: "" },
                                                on: { click: _vm.watch },
                                              },
                                              [_vm._v(" Watch ")]
                                            )
                                          : _c(
                                              "RtbButton",
                                              {
                                                staticClass: "mr-3",
                                                attrs: { outline: "" },
                                                on: { click: _vm.stop },
                                              },
                                              [_vm._v(" Pause ")]
                                            ),
                                      ]
                                    : _vm._e(),
                                  _c(
                                    "RtbButton",
                                    {
                                      staticClass: "mr-3",
                                      attrs: {
                                        disabled:
                                          _vm.working || _vm.isProcesingGif,
                                        color: "primary",
                                        outline: "",
                                      },
                                      on: { click: _vm.redo },
                                    },
                                    [_vm._v(" Redo ")]
                                  ),
                                  _c(
                                    "RtbButton",
                                    {
                                      attrs: {
                                        disabled:
                                          _vm.working || _vm.isProcesingGif,
                                        color: "primary",
                                      },
                                      on: { click: _vm.submit },
                                    },
                                    [
                                      _c(
                                        "v-flex",
                                        { attrs: { "d-flex": "" } },
                                        [
                                          _vm.working
                                            ? _c("v-progress-circular", {
                                                staticClass: "spinner",
                                                attrs: { indeterminate: "" },
                                              })
                                            : _vm._e(),
                                          _c(
                                            "v-flex",
                                            {
                                              attrs: {
                                                "align-self-center": "",
                                              },
                                            },
                                            [_vm._v("Submit")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                2
                              ),
                        ],
                  ],
                  2
                )
              : _vm._e(),
          ]
        : [
            !_vm.isUserSubmitMode
              ? _c(
                  "v-layout",
                  {
                    staticClass: "mission-instructions",
                    attrs: { column: "" },
                  },
                  [
                    _c(
                      "v-flex",
                      { attrs: { grow: "", "d-flex": "" } },
                      [
                        _c("ResizableText", {
                          attrs: { message: _vm.instructions },
                        }),
                      ],
                      1
                    ),
                    _vm.game.importedFromBreadcrumb && _vm.presentationMission
                      ? _c("v-flex", {
                          staticClass:
                            "mission-instructions__presentation-image",
                          style:
                            "background: url('" +
                            _vm.presentationMission +
                            "') center / contain no-repeat;",
                        })
                      : _vm._e(),
                  ],
                  1
                )
              : _c("v-flex", { attrs: { "align-center": "", "d-flex": "" } }, [
                  _c("h2", [_vm._v("Only Scribes Can Make Videos")]),
                ]),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }